import debounce from 'lodash-es';

export class equalElements {

  // Copied from another project

  constructor(containers, startWidth = 0, endWidth = 99999){ // could also use the ... spread operator
    const self = this;
    const windowWidth = window.innerWidth;

    self.containers = containers;
    self.startWidth = parseInt(startWidth, 10);
    self.endWidth = parseInt(endWidth, 10);


    self.setHeights();

    window.addEventListener("resize", self.resizeListener.bind(self));

    window.addEventListener("load", function(){
      if(windowWidth >= self.startWidth && windowWidth <= self.endWidth){
        self.setHeights();
      }

      else{
        self.unsetHeights();
      }
    });
  }

  resizeListener(){
    const self = this;
    const windowWidth = window.innerWidth;


    if(windowWidth >= self.startWidth && windowWidth <= self.endWidth){
      //debounce(self.setHeights.bind(self), 200); // breaks things.
      self.setHeights();
    }

    else{
      //debounce(self.unsetHeights, 200); // breaks things.
      self.unsetHeights();
    }


  }

  unsetHeights(cb){

    const self = this;
    const elements = [];

    Array.from(self.containers).forEach(function(container){
      let children = Array.from(container.children);

      if(children.length){
        children.forEach((child) => elements.push(child));
      }

    });

    elements.forEach(function(element){
      element.style.minHeight = "0";
    });

    setTimeout(cb);
  }

  setHeights(){
    const self = this;
    const windowWidth = window.innerWidth;

    if(windowWidth >= self.startWidth && windowWidth <= self.endWidth){


      self.unsetHeights(function(){
        Array.from(self.containers).forEach(function(container){
          const children = Array.from(container.children);

          let maxHeight = 0;

          children.forEach(function(child){
            maxHeight = child.offsetHeight > maxHeight ? child.offsetHeight : maxHeight;
          });

          children.forEach(function(child){
              child.style.minHeight = maxHeight + "px";
          });

        });
      });
    }
  }
}
