import skipLinkFocusFix from './components/skip-link-focus.js';
import ajaxLogin from './components/ajax-login.js';
import lazyLoad from './components/lazy-load.js';
import Navigation from './components/navigation.js';
import MobileNavigation from './components/mobile-navigation.js';
import ExternalLinks from './components/external-links.js';
import ArchiveSearch from './components/archive-search.js';
import DocumentSearch from './components/document-search.js';
import arrayFromPolyfill from './array-from.js';
import { equalElements } from './components/equal-elements.js';

arrayFromPolyfill();

const lazyLoaded = new lazyLoad(document.querySelectorAll('[lazy-load]'));
const navigation = new Navigation('#main-header');

navigation.makeAccessible();
navigation.enableSearch();

const mobileNavigation = new MobileNavigation('.mobile-menu');
mobileNavigation.bindMenuToggle('.menu-toggle');

const memberMobileMenu = document.querySelector('.member-mobile-menu')
if (memberMobileMenu) {
  const memberMobileNavigation = new MobileNavigation(memberMobileMenu);
  memberMobileNavigation.bindMenuToggle('.member-menu-toggle')
}

skipLinkFocusFix();
ajaxLogin();

const externalLinks = new ExternalLinks();
externalLinks.fixLinks(externalLinks.getAllExternalLinks());

if (document.querySelector('.search-event')) {
  const eventSearch = new ArchiveSearch();
  eventSearch.bindSearch('.search-event input[type="search"]');
  eventSearch.bindContainer('.archive-post-list');
  eventSearch.enableSearch();
}

if (document.querySelector('.searchable-archive')) {
  const documentSearch = new DocumentSearch();
  documentSearch.bindSearch('.searchable-archive input[type="search"]');
  documentSearch.bindContainer('.searchable-archive ul');
  documentSearch.enableSearch();
}

Array.from(document.querySelectorAll('.association .post-list')).forEach(container => {
  new equalElements([container], 961);
});

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('li.menu-item-has-children')).forEach(li => {
    li.addEventListener('mouseover', () => {
      const submenu = li.children[1];
      const submenuWidth = submenu.getBoundingClientRect().width;
      const liWidth = li.getBoundingClientRect().width;
      const left = (submenuWidth - liWidth) / 2;

      submenu.style.left = `-${left}px`;
    });
  });

  /*Array.from(document.querySelectorAll('.expand-post-list')).forEach(link => {
    link.addEventListener('click', e => {
      const link = e.target;
      const list = link.parentNode.querySelector('ul');


      Array.from(list.children).map(link => link.style.display = 'block');
      link.parentNode.removeChild(link);
      e.preventDefault();
    });
  });*/

  Array.from(document.querySelectorAll('.back-link')).forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      history.back();
    });
  });
});


