import 'whatwg-fetch';

export default class {
  constructor(elements){
    Array.from(elements).forEach(element => {
      this.initialLoad(element);
    });
  }

  initialLoad(element){
    const src = element.getAttribute('src');
    const poll = element.getAttribute('poll') || false;

    this.loadFromServer(src)
    .then(response => {
      element.innerHTML = response.text; // not very efficient..

      if(poll){
        setInterval(() => {
          this.loadFromServer(src)
          .then(response => {
            element.innerHTML = response.text;
          }).catch(error => {
            console.error(error);
            element.innerHTML = 'Sorry, this content failed to load. We\'ll try again in a moment.';
          });
        }, poll);
      }

    }).catch(error => {
      console.error(error);
      element.innerHTML = 'Sorry, this content failed to load. Refreshing the page usually helps';
    });
  }

  loadFromServer(src){
    return fetch(src).then(response => response.json());
  }
}
