//import ArchiveSearch from './archive-search.js';

//export default class DocumentSearch extends ArchiveSearch {
  //constructor() {
    //super();
  //}

  //enableSearch() {
    //Array.from(this.container.querySelectorAll('li a'))
      //.map(element => element.title = element.textContent.toLowerCase());

    //this.searchEl.closest('form').addEventListener('submit', e => {
      //e.preventDefault();
    //});
  //}
//}

export default class DocumentSearch {
  constructor() {
    this.delay = 150;
    this.searchId = null;
    this.container = null;
    this.searchEl = null;
  }

  bindSearch(input) {
    this.searchEl = input instanceof HTMLElement ? input : document.querySelector(input);
    this.searchEl.addEventListener('keyup', this.search.bind(this));
  }

  bindContainer(container) {
    this.container = container instanceof HTMLElement ? container : document.querySelector(container);
  }

  enableSearch() {
    Array.from(this.container.querySelectorAll('li a'))
      .map(element => element.title = element.textContent.toLowerCase());

    Array.from(this.container.querySelectorAll('li .file-desc'))
      .map(element => element.title = element.textContent.toLowerCase());
    this.searchEl.closest('form').addEventListener('submit', e => {
      e.preventDefault();
    });
  }

  search(event) {
    const term = event.target.value.toLowerCase();
    clearTimeout(this.searchId);

    this.searchId = setTimeout(() => {
      const all = Array.from(this.container.children);
      const hide = (element) => element.style.display = 'none';
      const show = (element) => element.style.display = 'block';
      const nores = this.container.querySelector('.no-results');

      if (term === '') {
        all.forEach(show);
        nores.style.display = 'none';
      } else {
        const results = Array.from(
          this.container.querySelectorAll(`li a[title*="${term}"], li .file-desc[title*="${term}"]`)
        )
        .map(element => element.closest('li'));

        if(results.length !== 0){
          nores.style.display = 'none';
        }

        all.forEach(hide);
        results.forEach(show);

        if(results.length === 0){
          nores.style.display = 'block';
        }
      }
    }, this.delay);
  }
}
