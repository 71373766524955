export default class Navigation {
  constructor(navigationElement) {
    this.navigation = navigationElement instanceof HTMLElement
                    ? navigationElement
                    : document.querySelector(navigationElement);
    this.keycodeMap = { 9: 'tab', 27: 'esc', 37: 'left', 38: 'up', 39: 'right', 40: 'down'};
  }

  enableSearch() {
    this.searchForm = this.navigation.querySelector('.site-search');
    this.navigation.querySelector('.search-link').addEventListener('click', this.showSearch.bind(this));
    //this.navigation.querySelector('.search-close').addEventListener('click', this.closeSearch.bind(this));
  }

  showSearch(e) {
    this.searchForm.classList.add('open');
    this.searchForm.addEventListener('transitionend', () => {
      e.target.style.display = 'none';
      this.searchForm.elements[0].focus();
    });

    e.preventDefault();
  }

  closeSearch() {

  }

  makeAccessible() {
    const traverse = (element, parent) => {
      const children = element.children;

      this.addAttributes(element, parent);
      this.addListeners(element, parent);

      if (Boolean(children.length)) {
        Array.from(children).forEach(child => traverse(child, element));
      }
    };

    traverse(this.navigation);
    this.bindKeys();
  }

  bindKeys() {
    // This kinda works, but then again it kinda doesn't.
    /*this.navigation.addEventListener('keydown', e => {
      const keycode = e.which;
      const currFocus = document.activeElement;
      switch(this.keycodeMap[keycode]){
        case 'esc':
          document.querySelector('.skip-link').focus();

          e.preventDefault();
        break;

        case 'left':
          if(currFocus.parentNode.previousElementSibling){
            currFocus.parentNode.previousElementSibling.children[0].focus();
          }

          e.preventDefault();
        break;

        case 'right':
          if(currFocus.parentNode.nextElementSibling){
            currFocus.parentNode.nextElementSibling.children[0].focus();
          }

          e.preventDefault();
        break;

        case 'up':
          if(currFocus.parentNode.previousElementSibling && currFocus.closest('.sub-menu')){
            currFocus.parentNode.previousElementSibling.children[0].focus();
          } else if(currFocus.parentNode.closest('.sub-menu').parentNode.classList.contains('menu-item-has-children')){ // this will be an error if it doesn't exist..
            currFocus.parentNode.closest('.sub-menu').parentNode.children[0].focus();
          }
          e.preventDefault();
        break;

        case 'down':
          if(currFocus.parentNode.classList.contains('menu-item-has-children')){
            currFocus.nextElementSibling.children[0].children[0].focus();
          } else if(currFocus.parentNode.nextElementSibling && currFocus.closest('.sub-menu')){
            currFocus.parentNode.nextElementSibling.children[0].focus();
          }

          e.preventDefault();
        break;


      }
    });*/
  }

  addAttributes(element, parent) {
    const classList = element.classList;

    if(classList.contains('menu-item-has-children')){
      element.setAttribute('aria-haspopup', 'true');
    }

    if(classList.contains('sub-menu')){
      element.setAttribute('aria-hidden', 'true');
    }

    if(parent){
      if(parent.classList.contains('sub-menu')){
        element.setAttribute('tabindex', '-1');
      }
    }
  }

  addListeners(element, parent) {
    const setActive = (newActive) => {
      Array.from(this.navigation.querySelectorAll('.sub-menu.focus')).forEach(menu => {
        menu.classList.remove('focus');
      });

      if(newActive){
        newActive.classList.add('focus');
      }
    };

    if(parent){
      if(parent.classList.contains('menu-item-has-children')){ // The link gets focus, we target the parentNode.
        element.addEventListener('focus', e => {
          setActive(parent.querySelector('.sub-menu'));
        });

        //console.log(element);

        Array.from(parent.querySelectorAll('.sub-menu a')).forEach(element => {
          element.addEventListener('blur', e => {
            console.log(document.activeElement);
          });
        });
      }
    }

    /*Array.from(document.querySelectorAll('*')).forEach(element => {
      element.addEventListener('focus', () => setActive(null));
    });*/
  }


}
