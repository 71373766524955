export default class MobileNavigation {
  constructor(navigationElement) {
    this.navigation = navigationElement instanceof HTMLElement
      ? navigationElement
      : document.querySelector(navigationElement);
    this.toggle = null;

    this.oldPositions = {
      1: {
        x: 1,
        y: 1,
      },
      2: {
        x: 1,
        y: 1
      }
    };

    this.attachToggleHandles();

    // Store instances globally so we can control the other instances too within this same code.
    if(Array.isArray(window.mobilenavigations)) {
      window.mobilenavigations.push(this);
    } else {
      window.mobilenavigations = [this];
    }
  }

  attachToggleHandles() {
    Array.from(this.navigation.querySelectorAll('.menu-item-has-children > a'))
      .forEach(this.attachToggleHandle.bind(this));
    return null;
  }

  attachToggleHandle(menu) {
    const span = document.createElement('span');
    span.className = 'handle';
    span.addEventListener('click', this.toggleSubMenu.bind(this));

    return menu.insertBefore(span, null);
  }


  bindMenuToggle(button) {
    this.toggle = button instanceof HTMLElement ? button : document.querySelector(button);
    return this.toggle.addEventListener('click', this.toggleMenu.bind(this));
  }

  toggleMenu() {
    const rectangles = [].slice.call(this.toggle.children[0].children, 1, 4);
    const menu = this.navigation;

    window.mobilenavigations.forEach(navigation => {
      if (navigation !== this && navigation.navigation.classList.contains('open')) {
        // check if it's open, and if it is, call .toggleMenu() on it
        navigation.toggleMenu();
      }
    });

    if (menu.classList.contains('open')) {
      rectangles.forEach(rectangle => rectangle.removeAttribute('style'));

      // Thank you IE and Firefox for being little babies and not coping with inline styles.
      rectangles[1].setAttribute('x', this.oldPositions[1].x);
      rectangles[1].setAttribute('y', this.oldPositions[1].y);
      rectangles[2].setAttribute('x', this.oldPositions[2].x);
      rectangles[2].setAttribute('y', this.oldPositions[2].y);

      return menu.classList.remove('open');
    } else {
      rectangles[0].style.display = "none";

      this.oldPositions[1].x = rectangles[1].getAttribute('x') || '';
      this.oldPositions[1].y = rectangles[1].getAttribute('y') || '';

      rectangles[1].style.transform = "rotate(-226deg)";
      rectangles[1].setAttribute('x', -15);
      rectangles[1].setAttribute('y', -22);

      this.oldPositions[2].x = rectangles[2].getAttribute('x') || '';
      this.oldPositions[2].y = rectangles[2].getAttribute('y') || '';

      rectangles[2].style.transform = "rotate(225deg)";
      rectangles[2].setAttribute('x', -36);
      rectangles[2].setAttribute('y', 0);

      return menu.classList.add('open');
    }

      /*if (menu.classList.contains('open')) {
      rectangles.forEach(rectangle => rectangle.removeAttribute('style'));
      return menu.classList.remove('open');
    } else {
      rectangles[0].style.display = "none";

      rectangles[1].style.transform = "rotate(-225deg)";
      rectangles[1].style.x = -15;
      rectangles[1].style.y = -22;

      rectangles[2].style.transform = "rotate(225deg)";
      rectangles[2].style.x = -36;
      rectangles[2].style.y = -1;

      return menu.classList.add('open');
    }*/
  }

  toggleSubMenu(e) {
    const li = e.target.parentNode.parentNode;
    const toggle = e.target;

    e.preventDefault();

    if (li.classList.contains('open')) {
      return li.classList.remove('open');
    } else {
      return li.classList.add('open');
    }
  }
}
