import 'whatwg-fetch';

export default function(){
  const ajaxLogin = e => {
    const form = e.target;
    const data = new FormData(form);
    const welcome = document.querySelector('.welcome');
    const headers = new Headers({
      'Response-Type': 'json'
    });

    fetch(form.action, {
      method: 'POST',
      body: data,
      headers: headers,
      credentials: 'include'
    })
    .then(response => response.text())
    .then(response => {
      // Because we sometimes get errors => not json
      response = JSON.parse(response);
      if(response.type === 'success'){
        console.log(response.message);
        return window.location.reload();

      } else {
        console.error(response.message);
        form.reset();
        form.classList.add('shake');
        return setTimeout(() => {
          form.classList.remove('shake');
          form.elements[0].focus();
        }, 500);
      }

      //welcome.style.height = `${form.offsetHeight}px`;
      //setTimeout(() => {
        //form.parentNode.classList.add('flip');
      //}, 50);

      //setTimeout(() => {
        //form.style.display = 'none';
      //}, 250);
    });

    e.preventDefault();
  };

  const ajaxLogout = e => {
    e.preventDefault();
    const data = new FormData();
    data.append('action', 'wp_ajax_logout');

    fetch('/wp-admin/admin-ajax.php', {
      method: 'POST',
      body: data,
      'credentials': 'include'
    }).then(response => response.text())
      .then(response => {
        response = JSON.parse(response);
        console.info(response);
        if (response.type === 'success') {
          window.location.reload();
        } else {
          alert('Uloskirjautuminen epäonnistui. Yritä uudelleen.');
        }
      }).catch(e => console.error(e));
  };

  Array.from(document.querySelectorAll('[action="/wp-login.php"]')).forEach(form => {
    form.addEventListener('submit', ajaxLogin);
  });

  /*Array.from(document.querySelectorAll('.log-out-link')).forEach(link => {
    link.addEventListener('click', ajaxLogout);
  });*/

}
